<template>
  <div class="content worker-data">
    <fm-title title-text="基础信息" v-if="workerData && (!type || type === 'base')"></fm-title>
    <fm-form :inline="4" label-alone label-align="left" v-if="workerData && (!type || type === 'base')" style="position: relative;">
      <fm-form-item label="姓名">
        <fm-input-new v-model="workerData.name" placeholder="姓名" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="性别">
        <fm-radio-group v-model="workerData.sex">
          <fm-radio value="男" :disabled="readOnly" label="男"></fm-radio>
          <fm-radio value="女" :disabled="readOnly" label="女"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="出生年月">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          :value="workerData.birth"
          @change="birthChange"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item></fm-form-item>
      <fm-form-item label="身份证号码">
        <fm-input-new @change="idNoChange" placeholder="身份证号码" v-model="workerData.idNo" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="年龄">
        <fm-input-new v-model="workerData.age" placeholder="年龄" :disabled="true"/>
      </fm-form-item>
      <fm-form-item label="民族">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.clan" :disabled="readOnly">
          <fm-option v-for="item in workerClanList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item></fm-form-item>
      <fm-form-item label="人员类别">
        <fm-input-new placeholder="人员类别" v-model="otherInfo.rylb" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="工作岗位">
        <fm-input-new placeholder="工作岗位" v-model="otherInfo.gzgw" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="入党日期">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="otherInfo.rdsj"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item></fm-form-item>
      <fm-form-item label="转正日期">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="otherInfo.zzrq"
          @change="zzrqChange"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="党龄">
        <fm-input-new v-model="otherInfo.dage" placeholder="党龄" :disabled="true"/>
      </fm-form-item>
      <fm-form-item label="从事专业技术职务">
        <fm-input-new placeholder="从事专业技术职务" v-model="otherInfo.zyjszw" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="新社会阶层类型">
        <fm-input-new placeholder="新社会阶层类型" v-model="otherInfo.shjclx" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="聘任起始日期">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="otherInfo.prqsrq"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="聘任终止日期">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="otherInfo.przzrq"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="手机号码">
        <fm-input-new placeholder="手机号码" v-model="workerData.phone" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="是否“农民工”">
        <fm-select absolute filterable :clearable="!readOnly" v-model="otherInfo.isLmg" :disabled="readOnly">
          <fm-option v-for="item in [{key: '是', label: '是'}, {key: '否', label: '否'}]" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="户籍所在地">
        <fm-input-new placeholder="户籍所在地" v-model="otherInfo.hjszd" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="现居住地">
        <fm-input-new placeholder="现居住地" v-model="otherInfo.jtzz" :disabled="readOnly"/>
      </fm-form-item>
      <worker-img v-if="workerData" :workerData="workerData" @headFileChange="headFileChange"></worker-img>
      <fm-form-item label="学历">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.education" :disabled="readOnly">
          <fm-option v-for="item in workerEducationList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="学位">
        <fm-select absolute filterable :clearable="!readOnly" v-model="workerData.degree" :disabled="readOnly">
          <fm-option v-for="item in workerEduDegrees" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
    <!-- <fm-title title-text="党籍信息" v-if="workerData && (!type || type === 'dj')"></fm-title>
    <fm-form :inline="4" label-alone label-align="left" v-if="workerData && (!type || type === 'base')" style="position: relative;">
      <fm-form-item label="入党时所在支部">
        <fm-input-new v-model="otherInfo.rdsszzb" placeholder="入党时所在支部" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="入党介绍人">
        <fm-input-new v-model="otherInfo.rdjsr" placeholder="入党介绍人" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="转入当前支部日期">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="otherInfo.zrdqzbrq"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="转出党支部名称">
        <fm-input-new v-model="otherInfo.zcdzbmc" placeholder="转出党支部名称" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="现任党内职务">
        <fm-input-new v-model="otherInfo.xrdlzw" placeholder="现任党内职务" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="流出日期">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="otherInfo.lcrq"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="外出流向">
        <fm-input-new v-model="otherInfo.wclx" placeholder="外出流向" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="外出原因">
        <fm-input-new v-model="otherInfo.wcyy" placeholder="外出原因" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="流动党员活动证编号">
        <fm-input-new v-model="otherInfo.hdzbh" placeholder="流动党员活动证编号" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="流入单位">
        <fm-input-new v-model="otherInfo.lrdw" placeholder="流入单位" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="流入单位党组织联系人及联系方式">
        <fm-input-new v-model="otherInfo.lrdwlxfs" placeholder="流入单位党组织联系人及联系方式" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="出国日期">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="otherInfo.cgrq"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="出国原因">
        <fm-input-new v-model="otherInfo.cgyy" placeholder="出国原因" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="出国后与组织联系情况">
        <fm-input-new v-model="otherInfo.cghlxqk" placeholder="出国后与组织联系情况" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="党籍处理方式">
        <fm-input-new v-model="otherInfo.djclfs" placeholder="党籍处理方式" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="回国日期">
        <fm-date-picker
          absolute
          :readonly="readOnly"
          v-model="otherInfo.hgrq"
          :clearable="!readOnly"
          placeholder="请选择"
          type="date"
          format="Y年M月D日"
        ></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="恢复组织生活情况">
        <fm-input-new v-model="otherInfo.hfzzshqk" placeholder="恢复组织生活情况" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="存入档案材料">
        <fm-input-new v-model="otherInfo.crdacl" placeholder="存入档案材料" :disabled="readOnly"/>
      </fm-form-item>
      <fm-form-item label="保管单位">
        <fm-input-new v-model="otherInfo.bgdw" placeholder="保管单位" :disabled="readOnly"/>
      </fm-form-item>
    </fm-form> -->
    <!-- <fm-title title-text="家庭信息" v-if="workerData && (!type || type === 'jt')"></fm-title>
    <table>
      <tr>
        <td>称谓</td>
        <td>姓名</td>
        <td>年龄</td>
        <td>政治面貌</td>
        <td>工作单位及职务</td>
      </tr>
      <tr v-for="(item, index) in otherInfo.family" :key="index">
        <td style="padding-right: 16px;padding-bottom: 10px;">
          <fm-input-new v-model="otherInfo.family[index].cw" placeholder="" :disabled="readOnly"/>
        </td>
        <td style="padding-right: 16px;padding-bottom: 10px;">
          <fm-input-new v-model="otherInfo.family[index].name" placeholder="" :disabled="readOnly"/>
        </td>
        <td style="padding-right: 16px;padding-bottom: 10px;">
          <fm-input-new v-model="otherInfo.family[index].age" placeholder="" :disabled="readOnly"/>
        </td>
        <td style="padding-right: 16px;padding-bottom: 10px;">
          <fm-input-new v-model="otherInfo.family[index].zzmm" placeholder="" :disabled="readOnly"/>
        </td>
        <td style="padding-right: 16px;padding-bottom: 10px;">
          <fm-input-new v-model="otherInfo.family[index].gzdwjzc" placeholder="" :disabled="readOnly"/>
        </td>
      </tr>
    </table> -->
  </div>
</template>

<script>

import WorkerImg from './workerImg'

import {
  dateOperating
} from '@/fmlib'

import {
  getBirthByIdNo,
  getAge
} from '@/syslib/tools'

export default {
  components: {
    WorkerImg
  },
  props: {
    sourceData: {
      type: Object
    },
    type: {
      type: String
    },
    funAuth: {
      type: Object
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      workerData: {},
      otherInfo: {
        family: [{}, {}, {}, {}, {}, {}],
        rdsj: null
      }
    }
  },
  watch: {
    sourceData: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.sourceData))
        this.workerData = data
        let info = data.eduHis ? JSON.parse(data.eduHis) : {}
        if (!info.family) {
          info.family = [{}, {}, {}, {}, {}, {}]
        } else if (info.family.length < 6) {
          let i  = info.family.length
          while (i < 6) {
            info.family.push({})
            i += 1
          }
        }
        this.otherInfo = info
        this.otherInfo.dage = getAge(this.otherInfo.zzrq)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    workerEduDegrees () {
      return this.$store.getters.selectDatas['worker_edu_degree']
    },
    workerClanList () {
      return this.$store.getters.selectDatas['worker_clan_list']
    },
    workerEducationList () {
      return this.$store.getters.selectDatas['worker_education_list']
    }
  },
  methods: {
    headFileChange (fileData) {
      this.$set(this.workerData, 'headFileId', fileData.id)
    },
    idNoChange (data) {
      let birth = getBirthByIdNo(data)
      if (birth) {
        this.$set(this.workerData, 'birth', birth)
        this.$set(this.workerData, 'age', getAge(this.workerData.birth))
      }
    },
    birthChange (birth) {
      this.workerData.birth = birth ? birth.replace(/年|月/g, '-').replace('日', '') : ''
      this.$set(this.workerData, 'age', getAge(this.workerData.birth))
    },
    zzrqChange (zzrq) {
      this.otherInfo.dage = zzrq ? zzrq.replace(/年|月/g, '-').replace('日', '') : ''
      this.$set(this.otherInfo, 'dage', getAge(this.otherInfo.zzrq))
    },
    getFormData () {
      let data = Object.assign({}, this.workerData)
      Object.keys(data).forEach((key) => {
        if (data[key] && data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      })
      let otherInfo = Object.assign({}, this.otherInfo)
      Object.keys(otherInfo).forEach((key) => {
        if (otherInfo[key] && otherInfo[key] instanceof Date) {
          otherInfo[key] = dateOperating.computeDay({days: 0, date: otherInfo[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      })
      data.eduHis = JSON.stringify(otherInfo)
      return data
    }
  }
}
</script>

<style scoped lang="less">
.img-c {
  height: 144px;
  width: 105px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #eee;
  .no-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  img {
    max-height: 144px;
    max-width: 105px;
  }
}

.worker-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
}
</style>
